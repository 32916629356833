import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
    {
        path: '/auth_callback',
        name: 'auth-callback',
        component: () => import('@/modules/auth/views/AuthCallbackView.vue'),
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@/modules/auth/views/LogoutView.vue'),
    },
];

export default routes;
