import { MODULES } from '@/constants';
import { Module } from 'vuex';

const initialState = {
    userTokens: {
        access_token: '',
        expires_in: -1,
        id_token: '',
        refresh_token: '',
        token_type: '',
    },
    tokenVerificationStatus:
        MODULES.AUTH.TOKEN_VERIFICATION_STATUS.TOKEN_UNCHECKED,
    userClaimId: {
        sub: '',
        aud: '',
        email_verified: false,
        token_use: '',
        auth_time: -1,
        iss: '',
        'cognito:username': '',
        exp: -1,
        given_name: '',
        iat: -1,
        email: '',
    },
    loginUrl: '',
    logoutUrl: '',
} as Auth.AuthState;

const getters = {
    [MODULES.AUTH.STORE.GETTER.USER_TOKENS](
        state: Auth.AuthState
    ): Auth.UserTokens {
        return { ...state.userTokens };
    },

    [MODULES.AUTH.STORE.GETTER.TOKEN_VERIFICATION_STATUS](
        state: Auth.AuthState
    ): string {
        return state.tokenVerificationStatus;
    },

    [MODULES.AUTH.STORE.GETTER.USER_CLAIM_ID](
        state: Auth.AuthState
    ): Auth.Claim {
        return { ...state.userClaimId };
    },

    [MODULES.AUTH.STORE.GETTER.LOGIN_URL](state: Auth.AuthState): string {
        return state.loginUrl;
    },

    [MODULES.AUTH.STORE.GETTER.LOGOUT_URL](state: Auth.AuthState): string {
        return state.logoutUrl;
    },
};

const mutations = {
    [MODULES.AUTH.STORE.MUTATION.CLEAN_USER_TOKENS](
        state: Auth.AuthState
    ): void {
        state.userTokens = { ...initialState.userTokens };
    },

    [MODULES.AUTH.STORE.MUTATION.SET_USER_TOKENS](
        state: Auth.AuthState,
        userTokens: Auth.UserTokens
    ): void {
        state.userTokens = { ...userTokens };
    },

    [MODULES.AUTH.STORE.MUTATION.SET_TOKEN_VERIFICATION_STATUS](
        state: Auth.AuthState,
        status: string
    ): void {
        state.tokenVerificationStatus = status;
    },

    [MODULES.AUTH.STORE.MUTATION.SET_USER_CLAIM_ID](
        state: Auth.AuthState,
        userClaimId: Auth.Claim
    ): void {
        state.userClaimId = { ...userClaimId };
    },

    [MODULES.AUTH.STORE.MUTATION.SET_LOGIN_URL](
        state: Auth.AuthState,
        loginUrl: string
    ): void {
        state.loginUrl = loginUrl;
    },

    [MODULES.AUTH.STORE.MUTATION.SET_LOGOUT_URL](
        state: Auth.AuthState,
        logoutUrl: string
    ): void {
        state.logoutUrl = logoutUrl;
    },
};

const actions = {};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
} as Module<Auth.AuthState, any>;
